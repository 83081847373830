import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User, UserAccessAuth } from '../../interfaces/users.interface';
import { StorageIndex } from '../../constants/storage.const';
import { WorkspaceUser } from '../../../modules/home/workspace/workspace.interface';
import { get2HoursLaterEpoch } from '../../helpers/date.helper';

@Injectable({ providedIn: 'root' })
export class AuthService {

    accessToken: string = null;
    user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    accessList$: BehaviorSubject<UserAccessAuth[]> = new BehaviorSubject<any>(null);
    workspace$: BehaviorSubject<WorkspaceUser> = new BehaviorSubject<any>(null);

    constructor() {
        if (this.isTimeout()) {
            this.signOut();
        } else {
            this.accessToken = localStorage.getItem(StorageIndex.TOKEN) ?? '';
            this.user$.next(localStorage.getItem(StorageIndex.USER)
                ? JSON.parse(localStorage.getItem(StorageIndex.USER)) : null);
            this.accessList$.next(localStorage.getItem(StorageIndex.ACCESS_LIST)
                ? JSON.parse(localStorage.getItem(StorageIndex.ACCESS_LIST)) : null);
            this.workspace$.next(localStorage.getItem(StorageIndex.WORKSPACE)
                ? JSON.parse(localStorage.getItem(StorageIndex.WORKSPACE)) : null);

        }
    }

    isTimeout() {
        const workspace: WorkspaceUser = localStorage.getItem(StorageIndex.WORKSPACE)
            ? JSON.parse(localStorage.getItem(StorageIndex.WORKSPACE)) : null;
        const timeout = workspace?.sessionExpired || 0;
        const currentTimestamp = Math.floor(new Date().getTime() / 1000);
        const result = timeout < currentTimestamp;

        if (!result) {
            workspace.sessionExpired = get2HoursLaterEpoch();
            localStorage.setItem(StorageIndex.WORKSPACE, JSON.stringify(workspace));
        }

        return result;
    }

    setAccessToken(token: string) {
        localStorage.setItem(StorageIndex.TOKEN, token);
        this.accessToken = token;
    }

    setUserData(user: User) {
        localStorage.setItem(StorageIndex.USER, JSON.stringify(user));
        this.user$.next(user);
    }

    setAccessList(accessList: any) {
        localStorage.setItem(StorageIndex.ACCESS_LIST, JSON.stringify(accessList));
        this.accessList$.next(accessList);
    }

    setWorkspace(workspace: WorkspaceUser) {
        localStorage.setItem(StorageIndex.WORKSPACE, JSON.stringify(workspace));
        this.workspace$.next(workspace);
    }

    signOut() {
        localStorage.removeItem(StorageIndex.TOKEN);
        localStorage.removeItem(StorageIndex.USER);
        localStorage.removeItem(StorageIndex.ACCESS_LIST);
        localStorage.removeItem(StorageIndex.WORKSPACE);
        this.accessToken = null;
        this.user$.next(null);
        this.accessList$.next(null);
        this.workspace$.next(null);
    }

    getUsername() {
        return this.user$?.value?.userName;
    }
}
